// MAIN.JS
jQuery(function($) {

	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
	}

	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/



	/****************************************************/

	//SCROLL

		$(window).scroll(function() {
		if ($(window).scrollTop() > 160) {
			$(".module-menu").addClass('scrolled');
		} else {
			$(".module-menu").removeClass('scrolled');
		}
	}).scroll();


	/**********************************************/

	//LAYERSLIDER
	$('.layerslider').layerSlider({
		autoStart: true,
		startInViewport: true,
		pauseOnHover: false,
		responsive: true,
		navButtons: true,
		navPrevNext: false,
		showCircleTimer: false,
	});

	/**********************************************/


	// SMOOTH SCROLL ANCHOR
	$('.smooth-scroll').click(function(e) {
		e.preventDefault();
		$target = $($(this).attr('href'));
		$navHeight = $(".module-menu .scrolled").outerHeight();
		$targetTopY = $target.offset().top - $navHeight;

		/* smooth scroll */
		TweenMax.to($(window), 1, {
			scrollTo: {
				y: $targetTopY,
				autoKill: true
			},
			ease: "Expo.easeInOut"
		});
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel();
	}

	/**********************************************/

	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});
	}

/**********************************************/

	// MASONRY
	if ($.fn.masonry) {
		$(window).load(function(){
			$('.grid').masonry({
				itemSelector: '.grid-item', // use a separate class for itemSelector, other than .col-
				columnWidth: '.grid-sizer',
				percentPosition: true
			});
		});
	}



/*************************************************************/


});
